'use client';

import {
	Popover,
	PopoverContent,
	PopoverTrigger,
} from '@/components/ui/popover';
import { Routes } from '@/lib/routes';
import clsx from 'clsx';
import { motion } from 'framer-motion';
import Link from 'next/link';
import { usePathname } from 'next/navigation';
import { useMediaQuery } from 'usehooks-ts';
import { LogotypeIcon } from '../Icons';

const navigationItems = [
	{
		label: 'Gallery',
		href: Routes.gallery,
		highlight: true,
	},
	{
		label: 'My Challenge',
		href: Routes.scheduler,
	},
	{
		label: 'Generator',
		href: Routes.generator,
	},
	{
		label: 'Guides',
		href: Routes.guides,
	},
	{
		label: 'My Profile',
		href: Routes.profile,
	},
	// {
	// 	label: 'Leaderboard',
	// 	href: Routes.leaderboard,
	// },
];

const hideOnPages = [Routes.root];

const Navigation = () => {
	const pathname = usePathname();
	const isDesktop = useMediaQuery('(min-width: 768px)');
	const NavigationItemsContent = (
		<>
			{navigationItems.map((item) => (
				<Link
					key={item.href}
					href={item.href}
					className={clsx(
						`relative hover:text-primary font-semibold flex gap-2 items-center px-4px max-md:text-xl ${
							pathname.startsWith(item.href)
								? 'text-primary'
								: 'text-slate-400 '
						}`,
						item.highlight && 'px-4 py-1 rounded-3xl overflow-hidden',
					)}
				>
					{item.highlight ? (
						<>
							<div className='absolute -top-[255%] md:-top-[75%] -left-[10%] w-[120%] h-[690%] md:h-[280%] bg-[linear-gradient(to_right,#ffffff,#76e4f7,#5d00ff)] bg-opacity-10 rounded-3xl z-0 animate-fullSpin' />
							<div className='absolute top-[6%] left-[1%] md:left-[3%] w-[98%] md:w-[94%] h-[88%] bg-white rounded-3xl z-0' />
						</>
					) : (
						<motion.div
							initial={false}
							className='z-10 w-3 h-3 max-md:w-4 max-md:h-4 rounded-full bg-[linear-gradient(to_right,#76e4f7,#b794f4)]'
							animate={{ scale: pathname.startsWith(item.href) ? 1 : 0 }}
						/>
					)}

					<span className='z-10'>{item.label}</span>
				</Link>
			))}
		</>
	);

	if (hideOnPages.includes(pathname)) return null;

	return (
		<div className='container mx-auto z-10 h-12 flex items-center justify-between'>
			<Link href={Routes.root} className='w-[150px]'>
				<LogotypeIcon />
			</Link>
			<Popover>
				<nav className='flex gap-[2vw] max-md:hidden'>
					{NavigationItemsContent}
				</nav>

				<PopoverTrigger className='hidden max-md:block'>
					<div className='w-10 h-10 rounded-full flex items-center justify-center'>
						<div className='w-3 h-3 rounded-full bg-primary' />
					</div>
				</PopoverTrigger>
				<PopoverContent
					className={clsx(isDesktop ? 'hidden' : '', 'rounded-3xl pl-6')}
					collisionPadding={10}
				>
					<nav className='flex flex-col gap-3'>{NavigationItemsContent}</nav>
				</PopoverContent>
			</Popover>
		</div>
	);
};

export default Navigation;
