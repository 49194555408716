'use client';

import { productHuntUrl } from '@/constants';
import { Analytics } from '@/lib/mixpanel';
import { usePathname } from 'next/navigation';

const PHBanner = () => {
	const path = usePathname();

	const handleClick = () => {
		Analytics.track('banner.productHunt.click');
		const windowRef = window.open(productHuntUrl, '_blank');
		// will open it on the same tab if the pop-up is blocked
		if (!windowRef) {
			window.location.replace(productHuntUrl);
		}
	};

	const show = false; // should be turned off 16 Jan, 17:00

	if (!['/', '/scheduler', '/generator', '/leaderboard'].includes(path))
		return null;

	if (!show) return null;

	return (
		<div
			className='bg-primary text-white p-3 flex justify-center cursor-pointer '
			onClick={handleClick}
			onKeyDown={handleClick}
		>
			<p className='text-center'>
				<span className='w-6 inline-block align-middle mr-2'>
					<svg
						viewBox='0 0 326 326'
						fill='none'
						xmlns='http://www.w3.org/2000/svg'
						aria-label='Product Hunt Logo'
					>
						<title>Product Hunt Logo</title>
						<g filter='url(#filter0_d_892_2102)'>
							<path
								d='M134.133 1.96796C103.6 7.43463 76.1333 21.568 53.4667 43.168C20.8 74.2346 4 113.568 4 158.501C4 235.968 58 300.901 134.267 315.301C151.067 318.368 177.2 318.101 194.533 314.501C239.467 305.301 277.333 278.235 300.667 238.501C326.4 194.501 328.8 137.168 306.8 91.0347C284 43.3013 242.533 11.4346 190.8 1.83463C176.933 -0.698704 148.533 -0.565371 134.133 1.96796ZM201.6 81.3013C216.4 86.2346 228.4 97.0347 235.6 111.701C239.067 118.501 239.333 120.501 239.333 134.501C239.333 149.035 239.067 150.235 235.067 158.635C229.733 169.435 217.733 181.168 207.067 186.235C199.467 189.701 198.667 189.835 169.733 190.235L140 190.635V214.635V238.501H123.333H106.667V158.501V78.5013H150C190.4 78.5013 194 78.6346 201.6 81.3013Z'
								fill='#fff'
							/>
							<path
								d='M140 134.368V158.501H162.8C176.933 158.501 187.733 157.835 191.067 156.901C206.933 152.101 212.667 131.301 201.6 119.435C194.533 111.835 192.133 111.301 165.067 110.768L140 110.235V134.368Z'
								fill='#fff'
							/>
						</g>
						<defs>
							<filter
								id='filter0_d_892_2102'
								x='0'
								y='-2.28882e-05'
								width='325.776'
								height='325.417'
								filterUnits='userSpaceOnUse'
								colorInterpolationFilters='sRGB'
							>
								<feFlood floodOpacity='0' result='BackgroundImageFix' />
								<feColorMatrix
									in='SourceAlpha'
									type='matrix'
									values='0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0'
									result='hardAlpha'
								/>
								<feOffset dy='4' />
								<feGaussianBlur stdDeviation='2' />
								<feComposite in2='hardAlpha' operator='out' />
								<feColorMatrix
									type='matrix'
									values='0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0'
								/>
								<feBlend
									mode='normal'
									in2='BackgroundImageFix'
									result='effect1_dropShadow_892_2102'
								/>
								<feBlend
									mode='normal'
									in='SourceGraphic'
									in2='effect1_dropShadow_892_2102'
									result='shape'
								/>
							</filter>
						</defs>
					</svg>
				</span>
				We're launching on Product Hunt! Your upvote would mean a lot to us.
				<span className='font-bold pl-2'>Click here to Vote now!</span>
			</p>
		</div>
	);
};

export default PHBanner;
